import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SecondChance = () => {
  const hasForm = false
  const images = [
    "https://res.cloudinary.com/nuvolum/image/upload/v1670442238/KTOMS/DEV/sa-22-01.png",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670442238/KTOMS/DEV/sa-22-02.png",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670442238/KTOMS/DEV/sa-22-04.png"
  ]

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language="en">
        <SEO
          lang="en"
          title="Smile Again Program at Kitsap Oral, Maxillofacial & Dental Implant Surgery"
          description="It is time for a brand new, healthy, beautiful smile."
        />

        <div className="sc-program">
          <div className="sc__hero">
            <div className="sc__hero--desktop">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-desktop-hero-image"
                alt="program banner"
              />
            </div>
            <div className="sc__hero--mobile">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-mobile-hero-image"
                alt="program banner"
              />
            </div>
          </div>

          <div className="sc__section">
            <div className="">
              <div className="sc__about-grid--content">
                <p className="sc__subheading">An Opportunity to Smile Again </p>
                <br />
                <p>
                  Kitsap Oral, Maxillofacial & Dental Implant Surgery is proud
                  to announce its 2022 Smile Again recipient. This life-changing
                  program provides a deserving recipient from our region with a
                  complete smile makeover free of charge.
                </p>
                <p>
                  Christina, 40, is a medical records tech at Naval Hospital
                  Bremerton and lives in Port Orchard. In her application,
                  Christina wrote about how her dental health wasn’t too bad as
                  a child, but as she began to have children, her pregnancies
                  took their toll on her teeth, as she got cavity after cavity,
                  leading to the need for root canals that weren’t covered by
                  her state-provided insurance. She had to have the teeth
                  removed, leading to more problems, including infections,
                  abscesses, and sleepless nights because of the pain. Now,
                  while she sees a dentist regularly, brushes well, and drinks
                  lots of water, her teeth continue to deteriorate. Christina
                  knows the importance of her smile, and plans to be promoted to
                  a lead in the medical records department soon, meaning she
                  will interact more with other departments and act as a
                  customer relations representative, interacting with the public
                  too. As the “face” of the department, she wants to be able to
                  show her confident smile rather than try to keep her mouth
                  closed or cover her mouth with her hands when she laughs or
                  smiles.
                </p>
                <p>
                  Christina also wrote about some of the other challenges she
                  has faced in her life. She grew up with a stepdad who was
                  mentally and physically abusive, and wasn’t allowed to go to
                  college until she was out on her own. After she had her two
                  children, one who is autistic, she went to college and
                  graduated with honors and three AA degrees. Now, as a single
                  parent, she is trying to overcome poverty and work her way
                  through life, providing her children with a life better than
                  what she had as a child and teenager.
                </p>

                <br />
                <p className="sc__subheading">Full-Arch Restoration</p>
                <br />
                <p>
                  <Link
                    to="/procedures/full-arch-restoration-silverdale-poulsbo-port-orchard-wa/"
                    title="Learn more about full-arch restoration"
                  >
                    Full-arch restoration
                  </Link>{" "}
                  is a healthy and effective way to replace many missing teeth.
                  The procedure works by securing a full prosthesis in the mouth
                  using as few as four dental implants. The result is a complete
                  smile restoration that looks and feels natural.
                </p>
              </div>


              <div className="sc__about-grid--info">
                <div className="sc__brackets sc__brackets--desktop">
                  <div>
                    <p className="sc__subheading">Treatment Partner:</p>
                    <ul>
                      <li>
                        Restorative Partner: Michael Brooks, DMD, MS, MACP,
                        PLLC, from{" "}
                        <a
                          href="https://www.peninsulaprosthodontics.com/"
                          title="Go to Peninsula Prosthodontics Website"
                          target="_blank"
                        >
                          Peninsula Prosthodontics
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {hasForm && (
            <>
              <div className="sc__apply-banner">
                <p>CLICK THE BUTTON BELOW TO FILL OUT THE FORM</p>
              </div>

              <div className="sc__section sc__form">
                <a
                  className="standard-button contained"
                  href="https://secureform.seamlessdocs.com/f/3c6015447ca5c7f9db6745a2da69cf97?embedded=true"
                  target="_blank"
                >
                  Submit Application
                </a>
              </div>
            </>
          )}

          <div className="sc__section">
            <p>
              Follow Christina’s journey to a new smile on{" "}
              <a
                href="https://www.facebook.com/KitsapOMS/"
                target="_blank"
                title="Follow us on Facebook"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/kitsapoms/"
                target="_blank"
                title="Follow us on Instagram"
              >
                Instagram
              </a>
              !
            </p>
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-silverdale-wa/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedures/full-arch-restoration-silverdale-poulsbo-port-orchard-wa/"
                  title="Learn more about full-arch restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
          <div className="sc__section close-sfs-slider close-sc-slider">
            <ImageSlider
              images={images}
              adaptiveHeight={true}
              useArrows={true}
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SecondChance
